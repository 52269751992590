import React from 'react'
import { Paper } from '@mui/material/'
import { usePicoWidgetStyles } from './PicoWidget.styles'
import Stack from '@mui/material/Stack'
import { alpha, SvgIcon, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

export const PicoWidget = () => {
    const styles = usePicoWidgetStyles()

    const history = useHistory()

    return (
        <Stack
            direction="column"
            p={2}
            height='300px'
            width='450px'
            justifyContent="center"
            sx={{
                borderRadius: '24px',
                // backgroundColor: 'figma.grayscale.tier7',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20)',
            }}>
            <Box>
                <Chip
                    label={
                        <Typography variant="14x400" color="figma.grayscale.textHier2">
                            New Feature
                        </Typography>
                    }
                    sx={(theme) => ({
                        height: 'auto',
                        backgroundColor: alpha(theme.palette.figma.accentColors.neon, 0.2),
                        color: 'figma.grayscale.textHier2',
                        py: 0.5,
                        px: 1.5,
                        '& .MuiChip-label': {
                            p: 0,
                        },
                        [theme.breakpoints.down('sm')]: {
                            flex: 1,
                            py: 1,
                            '& .MuiChip-label': {
                                flex: 1,
                            },
                        },
                    })}
                />
            </Box>
            <Stack direction="column" gap={1} pt={1.5} pb={2}>
                <Typography variant="18x700" color="figma.grayscale.textHier1">
                    PICO Prediction
                </Typography>
                <Typography variant="16x400" color="figma.grayscale.textHier2">
                    Accelerate the assessment and strategic planning process by rapidly producing a structured set of
                    JCA-relevant PICOs, through an AI-enabled review of carefully curated and trusted EU-specific
                    information sources.
                </Typography>
            </Stack>
            <Button
                // @ts-expect-error mui type
                variant="contained-sm-button"
                color="primary"
                onClick={() => history.push('/pico')}>
                Try out
            </Button>
        </Stack>
    )
}
