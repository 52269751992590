import React, { useEffect, useState } from 'react'
import SearchBar from '../Search/SearchBar'
import '../../style/Layout.scss'
import { ElevationScroll } from '../../style/layout-style'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as SpotIcon } from '../../assets/images/spot.svg'
import { ReactComponent as GlobalIcon } from '../../assets/images/earth-3.svg'
import ReplyIcon from '@mui/icons-material/Reply'
import ListItemText from '@mui/material/ListItemText'
import { DialogActions, DialogContent, DialogTitle } from '../Dashboard/dashboard-style'
import SvgIcon from '@mui/material/SvgIcon'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as HomeIcon } from '../../assets/images/Home_Icon.svg'
import { ReactComponent as OverviewIcon } from '../../assets/images/layout-dashboard-1.svg'
import { ReactComponent as ProductIcon } from '../../assets/images/product-summary.svg'
import { ReactComponent as MetricsIcon } from '../../assets/images/science-graph.svg'
import { ReactComponent as ResourcesIcon } from '../../assets/images/action-doc.svg'
import { ReactComponent as StrategicIcon } from '../../assets/images/Strategic_Icon.svg'
import { ReactComponent as GapIcon } from '../../assets/images/Gap_Icon.svg'
import { ReactComponent as EvidenceIcon } from '../../assets/images/Ev_Icon.svg'
import { ReactComponent as ActivityIcon } from '../../assets/images/Act_Icon.svg'
import { ReactComponent as VMIcon } from '../../assets/images/VM_Icon.svg'
import { ReactComponent as SnapshotIcon } from '../../assets/images/Camera_Icon.svg'
import { ReactComponent as ViewSnapshotIcon } from '../../assets/images/camera-double.svg'
import { ReactComponent as DownloadIcon } from '../../assets/images/Download_Icon.svg'
import { ReactComponent as LogoutIcon } from '../../assets/images/logout-1.svg'
import { ReactComponent as SettingsIcon } from '../../assets/images/settings-gear.svg'
import { ReactComponent as InfoIcon } from '../../assets/images/info-circle.svg'
import { ReactComponent as MapIcon } from '../../assets/images/maps-mark.svg'
import AtlasIcon from '../../shared/icons/AtlasIcon'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import { withRouter, useHistory, useLocation } from 'react-router-dom'
import store from '../../redux/store'
import { connect, useDispatch, useSelector } from 'react-redux'
import callSecureAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import _ from 'underscore'
import pptxgen from 'pptxgenjs'
import {
    atlasRoadMapValueLogoBlack,
    gradientLine,
    litePowerpointTitleBackground,
    whiteAtlasLogo,
} from '../../assets/encodedImages'
import { isStrategySelected, quarterOfYear } from '../../utilities/helpers'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useAuth0 } from '../../contexts/auth0-context'
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import { FormControlLabel, ListItemAvatar, ListItemSecondaryAction } from '@mui/material/'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ReactCountryFlag from 'react-country-flag'
import { countryLookup } from '../../utilities/countryLookup'
import GetAppIcon from '@mui/icons-material/GetApp'
import DescriptionIcon from '@mui/icons-material/Description'
import { WhiteOutlineCheckbox } from '../Support/CustomElements'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import FormControl from '@mui/material/FormControl'
import { Menu, MenuList, MenuItem, Select } from '@mui/material/'
import { reduxLogout, setFreeTrialStatus, setIsPicoEnabled, setSelectedMarket, setStrategy } from '../../redux/actions'
import Grid from '@mui/material/Grid'
import callAPI from '../../API/callSecureAPI'
import { useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Avatar from '@mui/material/Avatar'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg'
import { ClickAwayListener, Popper } from '@mui/material'
import DashBg from '../../assets/images/prodov-2EDIT2.png'
import { makeStyles } from '@mui/styles'
import { FIGMA_DARK_COLORS } from '../../style/colors'
import { NavbarInfoBanner } from './NavbarInfoBanner'
import { PulsingCircle } from '../../shared/components/PulsingCircle'
import { useExitSnapshotMode } from '../../shared/hooks/useExitSnapshotMode'

const routeBackgrounds = {
    '/': DashBg,
}

function mapDispatchToProps(dispatch) {
    return {
        setLocalMarket: (market) => dispatch(setSelectedMarket(market)),
        userLogout: () => dispatch(reduxLogout()),
        setStrategy: (strat) => dispatch(setStrategy(strat)),
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const useMenuListStyles = makeStyles(() => ({
    root: {
        padding: '0 !important',
    },
    list: {
        padding: '0 !important',
    },
    paper: {
        borderRadius: '16px !important',
        marginTop: '8px !important',
    },
}))

const DRAWER_WIDTH = 320

function ConnectedLayout(props) {
    const dispatch = useDispatch()

    const [strategy, setStrategy] = useState(_.get(store.getState(), 'strategy', null))
    const [organization, setOrganization] = useState({})
    const [showStrategy, setShowStrategy] = useState(false)
    const [settings, setSettings] = useState({})
    const [forceRefresh, setForceRefresh] = useState(false)
    const [menuItems, setMenuItems] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [errorModalText, setErrorModalText] = useState('')
    const [openPptxCustomizationModal, setOpenPptxCustomizationModal] = useState(false)
    const [includesValueProp, setIncludesValueProp] = useState(false)
    const [vmExporting, setVMExporting] = useState(false)
    const [vmAndEvidenceExporting, setVMAndEvidenceExporting] = useState(false)
    const [vmAndActExporting, setVMAndActExporting] = useState(false)
    const [siAndActExporting, setSIAndActExporting] = useState(false)
    const [evidenceExporting, setEvidenceExporting] = useState(false)
    const [EGPExporting, setEGPExporting] = useState(false)
    const [actExporting, setActExporting] = useState(false)
    const [vmsStatusGapsExporting, setVMsStatusGapsExporting] = useState(false)
    const [vmsGapsExporting, setVMsGapsExporting] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null)
    const [popperAnchorEl, setPopperAnchorEl] = useState(null)
    const [popperOpen, setPopperOpen] = useState(false)
    const { isLoading, user, loginWithRedirect, logout, getTokenSilently, isAuthenticated } = useAuth0()

    const [openSnapshot, setOpenSnapshot] = useState(false)
    const [newSnapshotNote, setNewSnapshotNote] = useState('')
    const [snapshotInProgress, setSnapshotInProgress] = useState(false)
    const [snapshotSaved, setSnapshotSaved] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const [categorizedVMs, setCategorizedVMs] = useState({})
    const [categories, setCategories] = useState([])
    const [categoryMap, setCategoryMap] = useState({})

    const { handleExitSnapshotMode } = useExitSnapshotMode()

    const currentBg = routeBackgrounds[location.pathname] || 'none'

    let pink = 'E4349B',
        white = 'FFFFFF',
        grey = 'EBEBEB',
        confidentialityFooterGrey = '918f8f',
        backgroundColor = 'ffffff',
        textColor = FIGMA_DARK_COLORS.grayscale.contentWrapper.replace('#',''),
        blueHeaders = '0049EA',
        MAX_CHARS_PER_SLIDE = 1600

    let strat = useSelector((state) => state.strategy)
    const selectedMarket = useSelector((state) => state.selectedMarket)
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const isDarkMode = useSelector((state) => (state?.userRecord ? state?.userRecord?.isDarkMode : true))

    let storeSettings = useSelector((state) => state.settings)

    const vmDisplayName = useSelector((state) =>
        state.settings && state.settings.displayNames ? state.settings.displayNames.valueMessage : ''
    )
    const vmsDisplayName = useSelector((state) =>
        state.settings && state.settings.displayNames ? state.settings.displayNames.valueMessages : ''
    )
    const activityDisplayName = useSelector((state) =>
        state.settings && state.settings.displayNames ? state.settings.displayNames.activity : ''
    )
    const activitiesDisplayName = useSelector((state) =>
        state.settings && state.settings.displayNames ? state.settings.displayNames.activities : ''
    )
    const evidenceDisplayName = useSelector((state) =>
        state.settings && state.settings.displayNames ? state.settings.displayNames.evidence : ''
    )
    const strategicDisplayName = useSelector((state) =>
        state.settings && state.settings.displayNames ? state.settings.displayNames.strategicImperatives : ''
    )
    const valueAndAccessDisplayName = useSelector((state) =>
        state.settings && state.settings.displayNames ? state.settings.displayNames.valueAndAccess : ''
    )

    useEffect(() => {
        if (!store.getState() || !store.getState().strategy) {
            return
        }
        setStrategy(store.getState().strategy)

        if (isAuthenticated) {
            getOrg()
            getSettings()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        if (strat?.isActive === false) {
            history.push('/roadmaps')
        }
    }, [strat])

    async function getSettings() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        }
    }

    const handleViewCurrentStrategy = async () => {
        await handleExitSnapshotMode()
        setForceRefresh(true)
    }

    async function beginCustomizingPptx() {
        if (!isStrategySelected()) {
            cannotExportPPTX('No Strategy selected. Please select a Strategy and try again.')
            return
        }

        setOpenPptxCustomizationModal(true)
    }

    async function exportPPTX(reportType) {
        let settings = await getSettings()
        let date = new Date()
        let dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let org = (
            await callSecureAPI(
                endpoints.getOrganizationById.url + store.getState().strategy.organizationId,
                endpoints.getOrganizationById.method,
                token
            )
        ).data

        let vms = await callSecureAPI(
            endpoints.getValueMessagesForTable.url + store.getState().strategy._id,
            endpoints.getValueMessagesForTable.method,
            token
        )

        if (reportType.includes(vmsDisplayName) && !vms.success) {
            cannotExportPPTX(`No ${vmsDisplayName}. Please add a new ${vmDisplayName} to your Strategy and try again.`)
            return
        }

        let tempCategorizedVMs = {}
        let tempCategoryMap = {}
        if (reportType.includes(vmsDisplayName)) {
            let incomingCategories = await callSecureAPI(
                endpoints.getNarrativeCategories.url + store.getState().strategy.organizationId,
                endpoints.getNarrativeCategories.method,
                token
            )

            if (incomingCategories.success) {
                for (let category of incomingCategories.data) {
                    tempCategoryMap[category._id] = category
                }
                setCategoryMap(tempCategoryMap)
                setCategories(incomingCategories.data)

                for (let category of incomingCategories.data) {
                    tempCategorizedVMs[category._id] = []
                }
                for (let vm of vms.data) {
                    tempCategorizedVMs[vm.categoryId].push(vm)
                }
                for (let category of Object.keys(tempCategorizedVMs)) {
                    let arrCopy = Array.from(tempCategorizedVMs[category])
                    arrCopy.sort((a, b) => a.order - b.order)
                    tempCategorizedVMs[category] = arrCopy
                }
                setCategorizedVMs(tempCategorizedVMs)
            }
        }

        let evidence = await callSecureAPI(
            endpoints.getEvidenceForTable.url + store.getState().strategy._id,
            endpoints.getEvidenceForTable.method,
            token
        )

        if (reportType.includes('Evidence') && !evidence.success) {
            cannotExportPPTX(
                `No ${
                    (store.getState().settings?.displayNames?.evidence &&
                        store.getState().settings?.displayNames?.evidence) ||
                    'Evidence'
                }. Please add new evidence to your strategy and try again.`
            )
            return
        }

        let pres = new pptxgen()

        defineTitleSlide(pres, org)

        defineContentSlide(pres, org)

        if (store.getState().strategy.sourceId) {
            dateString += ' (Snapshot of ' + new Date(store.getState().strategy.createdAt).toLocaleDateString() + ')'
        }
        // TITLE SLIDE
        // ------------------------------------------------------------------------------------------------------
        let slide = pres.addSlide('MASTER_SLIDE')
        // For some reason the date string isn't adding when set on MASTER_SLIDE template, must manually add
        slide.addText(
            [
                {
                    text: dateString,
                    options: {
                        fontSize: 8,
                        color: confidentialityFooterGrey,
                        fontFace: 'Arial',
                        breakLine: true,
                    },
                },
            ],
            { y: 5.25, x: 0.13, w: '90%', align: 'left' }
        )

        // VALUE PROPOSITION SLIDE
        // ------------------------------------------------------------------------------------------------------
        // Make this optional when you add checkboxes
        if (includesValueProp) {
            addValuePropSlide(pres)
        }

        // Value Message Table -- broken into groups of 3 with new slide for each
        // NOT using pptxgen's built-in auto-paginating table because it does not
        // support complex text object types
        let currentCharCount = 0,
            tableRowsWithHeaders = [],
            headers = [],
            headersCopy = []
        switch (reportType) {
            case vmsDisplayName:
                for (let category of Object.keys(tempCategorizedVMs)) {
                    let i,
                        j,
                        currentCharCount = 0,
                        tableRowsWithHeaders = [],
                        headers = [
                            {
                                text: tempCategoryMap[category]?.name + ' ' + vmsDisplayName,
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                        ]

                    // Set Up Table headers
                    tableRowsWithHeaders.push(headers)
                    let headersCopy = Array.from(tableRowsWithHeaders)

                    // ITERATE THROUGH VM'S IN EACH CATEGORY, PAGINATING IN CHUNKS
                    let vmsOnCurrentSlide = 0
                    let evidenceOnCurrentSlide = 0
                    for (i = 0, j = tempCategorizedVMs[category].length; i < j; i++) {
                        // let evidence = []
                        // if (
                        //     categorizedVMs[category][i].linkedEvidence &&
                        //     categorizedVMs[category][i].linkedEvidence.length > 0
                        // ) {
                        //     evidence = await callSecureAPI(
                        //         endpoints.getLinkedEvidence.url,
                        //         endpoints.getLinkedEvidence.method,
                        //         token,
                        //         { listOfEvidence: categorizedVMs[category][i].linkedEvidence }
                        //     )
                        //     evidence = evidence.data
                        // }
                        //
                        let vmEvidenceCharCount = 0
                        // for (let ev of evidence) {
                        //     vmEvidenceCharCount += ev.name.length
                        // }

                        // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                        // eslint-disable-next-line no-loop-func
                        function createSlide() {
                            var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                            newSlide.addTable(headersCopy, {
                                x: 0.5,
                                y: 0.7,
                                // colW: [3, 6],
                                // border: [{ color: textColor }, { color: textColor }, { color: textColor }, { color: textColor }],
                                border: { type: 'none' },
                                fill: backgroundColor,
                                color: textColor,
                                margin: 10,
                                rowH: 0.4,
                            })

                            vmsOnCurrentSlide = 0
                            evidenceOnCurrentSlide = 0
                        }

                        if (
                            currentCharCount + vmEvidenceCharCount > MAX_CHARS_PER_SLIDE ||
                            vmsOnCurrentSlide > 6 ||
                            evidenceOnCurrentSlide > 15
                        ) {
                            createSlide()
                            // reset counter and headers
                            currentCharCount = 0
                            headersCopy = Array.from(tableRowsWithHeaders)
                        }

                        // COMMENTED OUT WITH VMS PER SLIDE AT 3, THIS IS TO BE USED LATER
                        // WHEN MAKING CONFIGURABLE

                        // if (
                        //     currentCharCount + vmEvidenceCharCount > MAX_CHARS_PER_SLIDE ||
                        //     vmsOnCurrentSlide > 3 ||
                        //     evidenceOnCurrentSlide > 15
                        // ) {
                        //     createSlide()
                        //     // reset counter and headers
                        //     currentCharCount = 0
                        //     headersCopy = Array.from(tableRowsWithHeaders)
                        // }

                        let rowColor = i % 2 === 0 ? grey : white
                        // add this VM to current working slide
                        let rows = [
                            {
                                text: `${i + 1}. ${tempCategorizedVMs[category][i].name}`,
                                options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                            },
                        ]
                        // let bullets = []
                        // if (evidence && evidence.length > 0) {
                        //     for (let ev of evidence) {
                        //         evidenceOnCurrentSlide += 1
                        //         bullets.push({
                        //             text: ev.name,
                        //             options: { bullet: true, fontSize: 9 },
                        //         })
                        //     }
                        // } else {
                        //     bullets = ''gi
                        // }
                        // rows.push({ text: bullets, options: { fill: rowColor } })
                        headersCopy.push(rows)
                        currentCharCount += vmEvidenceCharCount
                        vmsOnCurrentSlide += 1

                        // last VM
                        if (i === j - 1) {
                            createSlide()
                        }
                    }
                }
                break
            case vmsDisplayName + ' + Evidence':
                for (let category of Object.keys(tempCategorizedVMs)) {
                    let i,
                        j,
                        currentCharCount = 0,
                        tableRowsWithHeaders = [],
                        headers = [
                            {
                                text: tempCategoryMap[category].name + ' ' + vmsDisplayName,
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                            {
                                text: 'EVIDENCE',
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                        ]

                    // Set Up Table headers
                    tableRowsWithHeaders.push(headers)
                    let headersCopy = Array.from(tableRowsWithHeaders)

                    // ITERATE THROUGH VM'S IN EACH CATEGORY, PAGINATING IN CHUNKS
                    let vmsOnCurrentSlide = 0
                    let evidenceOnCurrentSlide = 0
                    for (i = 0, j = tempCategorizedVMs[category].length; i < j; i++) {
                        let evidence = []
                        if (
                            tempCategorizedVMs[category][i].linkedEvidence &&
                            tempCategorizedVMs[category][i].linkedEvidence.length > 0
                        ) {
                            evidence = await callSecureAPI(
                                endpoints.getLinkedEvidence.url,
                                endpoints.getLinkedEvidence.method,
                                token,
                                { listOfEvidence: tempCategorizedVMs[category][i].linkedEvidence }
                            )
                            evidence = evidence.data
                        }

                        let vmEvidenceCharCount = 0
                        for (let ev of evidence) {
                            vmEvidenceCharCount += ev.name.length
                        }
                        // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                        // eslint-disable-next-line no-loop-func
                        function createSlide1() {
                            var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                            newSlide.addTable(headersCopy, {
                                x: 0.5,
                                y: 0.7,
                                // border: [{ color: textColor }, { color: textColor }, { color: textColor }, { color: textColor }],
                                border: { type: 'none' },
                                fill: backgroundColor,
                                color: textColor,
                                margin: 10,
                                rowH: 0.4,
                                colW: [3, 6],
                            })

                            vmsOnCurrentSlide = 0
                            evidenceOnCurrentSlide = 0
                        }

                        if (
                            (currentCharCount + vmEvidenceCharCount > MAX_CHARS_PER_SLIDE ||
                                vmsOnCurrentSlide > 3 ||
                                evidenceOnCurrentSlide > 15) &&
                            vmsOnCurrentSlide !== 0
                        ) {
                            createSlide1()
                            // reset counter and headers
                            currentCharCount = 0
                            headersCopy = Array.from(tableRowsWithHeaders)
                        }

                        let rowColor = i % 2 === 0 ? grey : white
                        // add this VM to current working slide
                        let rows = [
                            {
                                text: `${i + 1}. ${tempCategorizedVMs[category][i].name}`,
                                options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                            },
                        ]
                        let bullets = []
                        if (evidence && evidence.length > 0) {
                            for (let ev of evidence) {
                                evidenceOnCurrentSlide += 1

                                bullets.push({
                                    text: ev.name,
                                    options: { bullet: true, fontSize: vmEvidenceCharCount > 1500 ? 7 : 9 },
                                })
                            }
                        } else {
                            bullets = ''
                        }
                        rows.push({ text: bullets, options: { fill: rowColor } })
                        headersCopy.push(rows)
                        currentCharCount += vmEvidenceCharCount
                        vmsOnCurrentSlide += 1

                        // last VM
                        if (i === j - 1) {
                            createSlide1()
                        }
                    }
                }
                break
            case vmsDisplayName + ' + ' + activitiesDisplayName:
                for (let category of Object.keys(tempCategorizedVMs)) {
                    let i,
                        j,
                        currentCharCount = 0,
                        tableRowsWithHeaders = [],
                        headers = [
                            {
                                text: tempCategoryMap[category].name + ' ' + vmsDisplayName,
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                            {
                                text: activitiesDisplayName,
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                        ]

                    // Set Up Table headers
                    tableRowsWithHeaders.push(headers)
                    let headersCopy = Array.from(tableRowsWithHeaders)

                    // ITERATE THROUGH VM'S IN EACH CATEGORY, PAGINATING IN CHUNKS
                    let vmsOnCurrentSlide = 0
                    let activitiesOnCurrentSlide = 0
                    for (i = 0, j = tempCategorizedVMs[category].length; i < j; i++) {
                        let activities = []
                        if (
                            tempCategorizedVMs[category][i].linkedActivities &&
                            tempCategorizedVMs[category][i].linkedActivities.length > 0
                        ) {
                            activities = await callSecureAPI(
                                endpoints.getLinkedActivities.url,
                                endpoints.getLinkedActivities.method,
                                token,
                                { listOfActivities: tempCategorizedVMs[category][i].linkedActivities }
                            )
                            if (activities.success) {
                                activities = activities.data
                            }
                        }

                        let vmActivitiesCharCount = 0
                        for (let act of activities) {
                            vmActivitiesCharCount += act.title.length
                        }

                        // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                        // eslint-disable-next-line no-loop-func
                        function createSlide2() {
                            var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                            newSlide.addTable(headersCopy, {
                                x: 0.5,
                                y: 0.7,
                                // border: [{ color: textColor }, { color: textColor }, { color: textColor }, { color: textColor }],
                                border: { type: 'none' },
                                fill: backgroundColor,
                                color: textColor,
                                margin: 10,
                                rowH: 0.4,
                                colW: [3.5, 5.5],
                            })

                            vmsOnCurrentSlide = 0
                            activitiesOnCurrentSlide = 0
                        }

                        if (
                            (currentCharCount + vmActivitiesCharCount > MAX_CHARS_PER_SLIDE ||
                                vmsOnCurrentSlide > 3 ||
                                activitiesOnCurrentSlide > 15) &&
                            vmsOnCurrentSlide !== 0
                        ) {
                            createSlide2()
                            // reset counter and headers
                            currentCharCount = 0
                            headersCopy = Array.from(tableRowsWithHeaders)
                        }

                        let rowColor = i % 2 === 0 ? grey : white
                        // add this VM to current working slide
                        let rows = [
                            {
                                text: `${i + 1}. ${tempCategorizedVMs[category][i].name}`,
                                options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                            },
                        ]
                        let bullets = []
                        if (activities && activities.length > 0) {
                            for (let act of activities) {
                                activitiesOnCurrentSlide += 1

                                bullets.push({
                                    text: act.title,
                                    options: { bullet: true, fontSize: vmActivitiesCharCount > 1500 ? 7 : 9 },
                                })
                            }
                        } else {
                            bullets = ''
                        }
                        rows.push({ text: bullets, options: { fill: rowColor } })
                        headersCopy.push(rows)
                        currentCharCount += vmActivitiesCharCount
                        vmsOnCurrentSlide += 1

                        // last VM
                        if (i === j - 1) {
                            createSlide2()
                        }
                    }
                }
                break
            case 'Evidence':
                currentCharCount = 0
                tableRowsWithHeaders = []
                headers = [
                    {
                        text: 'EVIDENCE',
                        options: {
                            fill: backgroundColor,
                            color: pink,
                            valign: 'middle',
                            align: 'left',
                            bold: true,
                            // TRBL order
                            border: [{ color: white }, { color: white }, { color: pink, pt: 3 }, { color: white }],
                        },
                    },
                ]

                let evidence = await callSecureAPI(
                    endpoints.getEvidenceForTable.url + store.getState().strategy._id,
                    endpoints.getEvidenceForTable.method,
                    token
                )

                if (!evidence.success) {
                    console.log('Failed to fetch evidence')
                    return
                }

                // Set Up Table headers
                tableRowsWithHeaders.push(headers)
                headersCopy = Array.from(tableRowsWithHeaders)

                // ITERATE EVIDENCE PAGINATING IN CHUNKS
                let evidenceOnCurrentSlide = 0
                for (let i = 0; i < evidence.data.length; i++) {
                    // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                    // eslint-disable-next-line no-loop-func
                    function createSlide3() {
                        var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                        newSlide.addTable(headersCopy, {
                            x: 0.5,
                            y: 0.7,
                            // border: [{ color: textColor }, { color: textColor }, { color: textColor }, { color: textColor }],
                            border: { type: 'none' },
                            fill: backgroundColor,
                            color: textColor,
                            margin: 10,
                            rowH: 0.4,
                        })

                        evidenceOnCurrentSlide = 0
                    }

                    if (evidenceOnCurrentSlide > 4 && evidenceOnCurrentSlide !== 0) {
                        createSlide3()
                        // reset counter and headers
                        currentCharCount = 0
                        headersCopy = Array.from(tableRowsWithHeaders)
                    }

                    let rowColor = i % 2 === 0 ? grey : white
                    // add this VM to current working slide
                    let rows = [
                        {
                            text: `${evidence.data[i].name}`,
                            options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                        },
                    ]

                    headersCopy.push(rows)
                    evidenceOnCurrentSlide += 1

                    // last evidence
                    if (i === evidence.data.length - 1) {
                        createSlide3()
                    }
                }
                break
            case activitiesDisplayName:
                currentCharCount = 0
                tableRowsWithHeaders = []
                headers = [
                    {
                        text: activitiesDisplayName,
                        options: {
                            fill: backgroundColor,
                            color: pink,
                            valign: 'middle',
                            align: 'left',
                            bold: true,
                            // TRBL order
                            border: [{ color: white }, { color: white }, { color: pink, pt: 3 }, { color: white }],
                        },
                    },
                    {
                        text: 'OBJECTIVE / RATIONALE',
                        options: {
                            fill: backgroundColor,
                            color: pink,
                            valign: 'middle',
                            align: 'left',
                            bold: true,
                            // TRBL order
                            border: [{ color: white }, { color: white }, { color: pink, pt: 3 }, { color: white }],
                        },
                    },
                    {
                        text: 'START',
                        options: {
                            fill: backgroundColor,
                            color: pink,
                            valign: 'middle',
                            align: 'left',
                            bold: true,
                            // TRBL order
                            border: [{ color: white }, { color: white }, { color: pink, pt: 3 }, { color: white }],
                        },
                    },
                    {
                        text: 'END',
                        options: {
                            fill: backgroundColor,
                            color: pink,
                            valign: 'middle',
                            align: 'left',
                            bold: true,
                            // TRBL order
                            border: [{ color: white }, { color: white }, { color: pink, pt: 3 }, { color: white }],
                        },
                    },
                ]

                let activities = await callSecureAPI(
                    endpoints.getActivitiesForTable.url + store.getState().strategy._id,
                    endpoints.getActivitiesForTable.method,
                    token
                )

                if (!activities.success) {
                    console.log('Failed to fetch evidence')
                    return
                }

                // Set Up Table headers
                tableRowsWithHeaders.push(headers)
                headersCopy = Array.from(tableRowsWithHeaders)

                // ITERATE EVIDENCE PAGINATING IN CHUNKS
                let activitiesOnCurrentSlide = 0
                for (let i = 0; i < activities.data.length; i++) {
                    // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                    // eslint-disable-next-line no-loop-func
                    function createSlide4() {
                        var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                        newSlide.addTable(headersCopy, {
                            x: 0.5,
                            y: 0.7,
                            // border: [{ color: textColor }, { color: textColor }, { color: textColor }, { color: textColor }],
                            border: { type: 'none' },
                            fill: backgroundColor,
                            color: textColor,
                            margin: 10,
                            rowH: 0.4,
                        })

                        activitiesOnCurrentSlide = 0
                    }

                    if (activitiesOnCurrentSlide > 5 && activitiesOnCurrentSlide !== 0) {
                        createSlide4()
                        // reset counter and headers
                        currentCharCount = 0
                        headersCopy = Array.from(tableRowsWithHeaders)
                    }

                    let rowColor = i % 2 === 0 ? grey : white
                    // add this VM to current working slide
                    let startDate = new Date(activities.data[i].startDate)
                    let endDate = new Date(activities.data[i].endDate)

                    let rows = [
                        {
                            text: `${activities.data[i].title}`,
                            options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                        },
                        {
                            text: `${activities.data[i].description}`,
                            options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                        },
                        {
                            text: activities.data[i].startDate
                                ? `Q${quarterOfYear(startDate)}, ${startDate.getFullYear()}`
                                : '',
                            options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                        },
                        {
                            text: activities.data[i].endDate
                                ? `Q${quarterOfYear(endDate)}, ${endDate.getFullYear()}`
                                : '',
                            options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                        },
                    ]

                    headersCopy.push(rows)
                    activitiesOnCurrentSlide += 1

                    // last evidence
                    if (i === activities.data.length - 1) {
                        createSlide4()
                    }
                }
                break
            case 'Strategic Imperatives + ' + activitiesDisplayName:
                let strategicImperatives = await callAPI(
                    endpoints.getSIForTable.url + store.getState().strategy._id,
                    endpoints.getSIForTable.method,
                    token
                )
                if (!strategicImperatives.success) {
                    console.log('Failed to fetch SIs')
                    return
                }

                strategicImperatives = strategicImperatives.data

                currentCharCount = 0
                tableRowsWithHeaders = []
                let headerNames = [
                    strategicDisplayName,
                    activityDisplayName + ' TITLE',
                    'CATEGORY',
                    'FUNCTION',
                    'Q1',
                    'Q2',
                    'Q3',
                    'Q4',
                    'Q1',
                    'Q2',
                    'Q3',
                    'Q4',
                    'Q1',
                    'Q2',
                    'Q3',
                    'Q4',
                ]
                let widths = [1.4, 1, 1, 1, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4]
                headers = []
                for (let i = 0; i < headerNames.length; i++) {
                    headers.push({
                        text: headerNames[i],
                        options: {
                            fontSize: i > 3 ? 7 : 8,
                            fill: backgroundColor,
                            color: headerNames[i] === strategicDisplayName ? blueHeaders : pink,
                            valign: 'middle',
                            align: 'center',
                            bold: true,
                            width: widths[i],
                            // TRBL order
                            border: [{ color: white }, { color: white }, { color: pink, pt: 3 }, { color: white }],
                        },
                    })
                }
                // Set Up Table headers
                tableRowsWithHeaders.push(headers)
                let headersCopy1 = Array.from(tableRowsWithHeaders)

                // ITERATE THROUGH VM'S IN EACH CATEGORY, PAGINATING IN CHUNKS
                let sisOnCurrentSlide = 0
                let actsOnSlide = 0
                for (let i = 0, j = strategicImperatives.length; i < j; i++) {
                    let activities = []
                    if (
                        strategicImperatives[i].linkedActivities &&
                        strategicImperatives[i].linkedActivities.length > 0
                    ) {
                        activities = await callSecureAPI(
                            endpoints.getLinkedActivities.url,
                            endpoints.getLinkedActivities.method,
                            token,
                            { listOfActivities: strategicImperatives[i].linkedActivities }
                        )
                        if (activities.success) {
                            activities = activities.data
                        }
                    }

                    let siActivitiesCharCount = 0
                    for (let act of activities) {
                        siActivitiesCharCount += act.title.length
                    }
                    // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                    // eslint-disable-next-line no-loop-func
                    function createSlide6() {
                        var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                        newSlide.addTable(headersCopy1, {
                            x: 0.5,
                            y: 0.7,
                            border: { type: 'none' },
                            fill: backgroundColor,
                            color: textColor,
                            margin: 10,
                            rowH: 0.4,
                            colW: [1.4, 1, 1, 1, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4],
                        })

                        sisOnCurrentSlide = 0
                        actsOnSlide = 0
                        let thisYear = new Date().getFullYear()
                        newSlide.addText(
                            [
                                {
                                    text: thisYear,
                                    options: {
                                        color: pink,
                                        fontSize: 7,
                                        align: 'left',
                                        bold: true,
                                    },
                                },
                            ],
                            { y: 0.63, x: 5.51 }
                        )

                        newSlide.addText(
                            [
                                {
                                    text: thisYear + 1,
                                    options: {
                                        color: pink,
                                        fontSize: 7,
                                        align: 'left',
                                        bold: true,
                                    },
                                },
                            ],
                            { y: 0.63, x: 7.11 }
                        )
                        newSlide.addText(
                            [
                                {
                                    text: thisYear + 2,
                                    options: {
                                        color: pink,
                                        fontSize: 7,
                                        align: 'left',
                                        bold: true,
                                    },
                                },
                            ],
                            { y: 0.63, x: 8.68 }
                        )
                        newSlide.addText(
                            [
                                {
                                    text: '△ Start',
                                    options: {
                                        color: pink,
                                        fontSize: 7,
                                        align: 'left',
                                        bold: true,
                                    },
                                },
                            ],
                            { y: 5.03, x: 4.93 }
                        )
                        newSlide.addText(
                            [
                                {
                                    text: '◈ End',
                                    options: {
                                        color: blueHeaders,
                                        fontSize: 7,
                                        align: 'left',
                                        bold: true,
                                    },
                                },
                            ],
                            { y: 5.03, x: 6.18 }
                        )
                    }

                    if (
                        (currentCharCount + siActivitiesCharCount > MAX_CHARS_PER_SLIDE ||
                            sisOnCurrentSlide > 1 ||
                            actsOnSlide > 15) &&
                        sisOnCurrentSlide !== 0
                    ) {
                        createSlide6()
                        // reset counter and headers
                        currentCharCount = 0
                        headersCopy1 = Array.from(tableRowsWithHeaders)
                    }

                    let rowColor = i % 2 === 0 ? grey : white
                    // add this VM to current working slide
                    let rows = [
                        {
                            text: `${strategicImperatives[i].name}`,
                            options: { fontSize: 9, fill: rowColor, align: 'center' },
                        },
                    ]
                    if (activities && activities.length > 0) {
                        let year = new Date().getFullYear()
                        let fields = ['title', 'category', 'function']
                        let numberOfYears = 3
                        for (let i = 0; i < numberOfYears; i++) {
                            for (let n = 1; n <= 4; n++) {
                                fields.push(`Q${n}, ${year + i}`)
                            }
                        }

                        actsOnSlide += 1
                        for (let field of fields) {
                            let rowActivities = []
                            let counter = 0
                            for (let act of activities) {
                                if (counter === 3) {
                                    // limits the number of activities to 2 per slide...
                                    // some smarter pagination could possibly remove the need for this one day
                                    break
                                }
                                let start = null
                                let end = null
                                if (act['startDate']) {
                                    start = new Date(act['startDate'])
                                }
                                if (act['endDate']) {
                                    end = new Date(act['endDate'])
                                }

                                let breaks = Math.floor(act['title'].length / 12)
                                if (field === 'startDate' || field === 'endDate') {
                                    rowActivities.push({
                                        text: `Q${quarterOfYear(new Date(act[field]))} ${new Date(
                                            act[field]
                                        ).getFullYear()}`,
                                        options: { fontSize: 9, align: 'center', breakLine: true },
                                    })
                                }
                                if (field.includes('Q')) {
                                    if (!start || !end) {
                                        rowActivities.push({
                                            text: '.',
                                            options: { fontSize: 9, align: 'center', breakLine: true, color: rowColor },
                                        })
                                        break
                                    }
                                    let text = ''

                                    let parseYear = field.split(',')
                                    let year = parseInt(parseYear[1])
                                    let fieldQuarter = parseInt(field[1])

                                    let actYearStart = start.getFullYear()
                                    let actYearEnd = end.getFullYear()
                                    let actQuarterStart = quarterOfYear(start)
                                    let actQuarterEnd = quarterOfYear(end)

                                    let color = textColor
                                    if (
                                        year < actYearStart ||
                                        (year === actYearStart && fieldQuarter < actQuarterStart) ||
                                        year > actYearEnd ||
                                        (year === actYearEnd && fieldQuarter > actQuarterEnd)
                                    ) {
                                        // outside range
                                        text = '.'
                                        color = rowColor
                                    } else if (fieldQuarter === actQuarterStart && year === actYearStart) {
                                        // start date
                                        text = '△'
                                        color = pink
                                    } else if (fieldQuarter === actQuarterEnd && year === actYearEnd) {
                                        // end date
                                        text = '◈'
                                        color = blueHeaders
                                    } else {
                                        // between start/end
                                        text = '-'
                                    }

                                    rowActivities.push({
                                        text: text,
                                        options: {
                                            color: color,
                                            fontSize: 9,
                                            align: 'center',
                                            breakLine: true,
                                        },
                                    })
                                } else {
                                    rowActivities.push({
                                        text: `${act[field]}`,
                                        options: { fontSize: 9, align: 'center', breakLine: true },
                                    })
                                }
                                rowActivities.push({
                                    text: '\n',
                                    options: { fontSize: 9, align: 'center', breakLine: true },
                                })
                                if (field !== 'title' && counter !== activities.length - 1) {
                                    if (field !== 'function' || act['function'].length < 8)
                                        for (let x = 0; x < breaks; x++) {
                                            rowActivities.push({
                                                text: '\n',
                                                options: { fontSize: 9, align: 'center', breakLine: true },
                                            })
                                        }
                                }
                                counter++
                            }

                            rows.push({ text: rowActivities, options: { fontSize: 9, fill: rowColor } })
                        }
                    } else {
                        // number of columns, need to add empty text to cells otherwise
                        // there are cell borders
                        rows.push({
                            text: 'None to display',
                            options: {
                                fontSize: 9,
                                align: 'center',
                                breakLine: true,
                                fill: rowColor,
                                valign: 'middle',
                                italic: true,
                            },
                        })
                        for (let n = 0; n < 14; n++) {
                            rows.push({
                                text: '',
                                options: { fontSize: 9, align: 'center', breakLine: true, fill: rowColor },
                            })
                        }
                    }
                    headersCopy1.push(rows)
                    currentCharCount += siActivitiesCharCount
                    sisOnCurrentSlide += 1

                    // last VM
                    if (i === j - 1) {
                        createSlide6()
                    }
                }
                break
            case 'EGP Objectives + ' + activitiesDisplayName:
                let EGPstrategicImperatives = await callAPI(
                    endpoints.getSIForTable.url + store.getState().strategy._id,
                    endpoints.getSIForTable.method,
                    token
                )
                if (!EGPstrategicImperatives.success) {
                    console.log('Failed to fetch SIs')
                    return
                }

                EGPstrategicImperatives = EGPstrategicImperatives.data
                let EGPTableRowsWithHeaders = []
                let EGPHeaderNames = [activityDisplayName, 'CATEGORY', 'FUNCTION', 'START', 'END', 'BUDGET']
                let EGPWidths = [3.4, 1, 1, 1, 1, 1]
                let EGPHeaders = []
                for (let i = 0; i < EGPHeaderNames.length; i++) {
                    EGPHeaders.push({
                        text: EGPHeaderNames[i],
                        options: {
                            fontSize: 8,
                            fill: backgroundColor,
                            color: EGPHeaderNames[i] === activityDisplayName ? blueHeaders : pink,
                            valign: 'middle',
                            align: 'center',
                            bold: true,
                            width: EGPWidths[i],
                            // TRBL order
                            border: [{ color: white }, { color: white }, { color: pink, pt: 3 }, { color: white }],
                        },
                    })
                }
                // Set Up Table headers
                EGPTableRowsWithHeaders.push(EGPHeaders)
                let EGPHeadersCopy1 = Array.from(EGPTableRowsWithHeaders)

                // ITERATE THROUGH VM'S IN EACH CATEGORY, PAGINATING IN CHUNKS
                for (let i = 0, j = EGPstrategicImperatives.length; i < j; i++) {
                    let EGPactsOnSlide = 0

                    function createSlide7() {
                        var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                        newSlide.addTable(EGPHeadersCopy1, {
                            x: 0.5,
                            y: 1.1,
                            border: { type: 'none' },
                            fill: backgroundColor,
                            color: textColor,
                            margin: 10,
                            rowH: 0.4,
                            colW: [3.4, 1, 1, 1, 1, 1],
                        })
                        newSlide.addText(
                            [
                                {
                                    text: EGPstrategicImperatives[i].name,
                                    options: {
                                        color: blueHeaders,
                                        fontSize: 11,
                                        align: 'left',
                                        bold: true,
                                    },
                                },
                            ],
                            { y: 0.8, x: 0.3 }
                        )
                        newSlide.addText(
                            [
                                {
                                    text: 'EGP OBJECTIVE:',
                                    options: {
                                        color: blueHeaders,
                                        fontSize: 11,
                                        align: 'left',
                                        bold: true,
                                    },
                                },
                            ],
                            { y: 0.5, x: 0.3 }
                        )
                        EGPactsOnSlide = 0
                    }

                    let activities = []
                    if (
                        EGPstrategicImperatives[i].linkedActivities &&
                        EGPstrategicImperatives[i].linkedActivities.length > 0
                    ) {
                        activities = await callSecureAPI(
                            endpoints.getLinkedActivities.url,
                            endpoints.getLinkedActivities.method,
                            token,
                            { listOfActivities: EGPstrategicImperatives[i].linkedActivities }
                        )
                        if (activities.success) {
                            activities = activities.data
                        }
                    }

                    // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                    // eslint-disable-next-line no-loop-func

                    // add this VM to current working slide

                    if (activities && activities.length > 0) {
                        for (let [index, act] of activities.entries()) {
                            let rowColor = index % 2 === 0 ? grey : white

                            if (EGPactsOnSlide > 4) {
                                createSlide7()
                                // reset counter and headers
                                EGPHeadersCopy1 = Array.from(EGPTableRowsWithHeaders)
                            }

                            let startDate
                            let endDate
                            if (act.startDate) {
                                startDate = new Date(act.startDate)
                            }
                            if (act.endDate) {
                                endDate = new Date(act.endDate)
                            }
                            let rowActivities = [
                                {
                                    text: `${act.title}`,
                                    options: { fontSize: 8, fill: rowColor, fontFace: 'Arial' },
                                },
                                {
                                    text: `${act.category}`,
                                    options: { fontSize: 8, fill: rowColor, fontFace: 'Arial', align: 'center' },
                                },
                                {
                                    text: `${act.function}`,
                                    options: { fontSize: 8, fill: rowColor, fontFace: 'Arial', align: 'center' },
                                },
                                {
                                    text: startDate ? `Q${quarterOfYear(startDate)}, ${startDate.getFullYear()}` : '',
                                    options: { fontSize: 8, fill: rowColor, fontFace: 'Arial', align: 'center' },
                                },
                                {
                                    text: endDate ? `Q${quarterOfYear(endDate)}, ${endDate.getFullYear()}` : '',
                                    options: { fontSize: 8, fill: rowColor, fontFace: 'Arial', align: 'center' },
                                },
                                {
                                    text:
                                        '$' +
                                        act.budget.toLocaleString(undefined, {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }),
                                    options: { fontSize: 8, fill: rowColor, fontFace: 'Arial', align: 'center' },
                                },
                            ]
                            EGPHeadersCopy1.push(rowActivities)
                            EGPactsOnSlide += 1
                        }
                    }
                    createSlide7()
                    EGPHeadersCopy1 = Array.from(EGPTableRowsWithHeaders)
                }
                break
            case vmsDisplayName + ' + Gaps':
                let gaps = await callSecureAPI(
                    endpoints.getEvidenceGapsByStrategyId.url + store.getState().strategy._id,
                    endpoints.getEvidenceGapsByStrategyId.method,
                    token
                )
                let acts = await callSecureAPI(
                    endpoints.getActivitiesForTable.url + store.getState().strategy._id,
                    endpoints.getActivitiesForTable.method,
                    token
                )

                let gapMap = {}
                for (let gap of gaps.data) {
                    if (gapMap[gap.valueMessageId]) {
                        gapMap[gap.valueMessageId].push(gap)
                        let sorted = gapMap[gap.valueMessageId].sort((x, y) => x.order - y.order)
                        gapMap[gap.valueMessageId] = sorted
                    } else {
                        gapMap[gap.valueMessageId] = [gap]
                    }
                }

                let activitiesMap = {}
                for (let act of acts.data) {
                    activitiesMap[act._id] = act
                }

                for (let category of Object.keys(tempCategorizedVMs)) {
                    let i,
                        j,
                        currentCharCount = 0,
                        tableRowsWithHeaders = [],
                        headers = [
                            {
                                text: tempCategoryMap[category].name + ' ' + vmsDisplayName,
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                            {
                                text: 'GAPS',
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                            {
                                text: 'ACTIVITIES',
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                        ]

                    // Set Up Table headers
                    tableRowsWithHeaders.push(headers)
                    let headersCopy = Array.from(tableRowsWithHeaders)

                    // ITERATE THROUGH VM'S IN EACH CATEGORY, PAGINATING IN CHUNKS
                    let vmsOnCurrentSlide = 0
                    let evidenceOnCurrentSlide = 0
                    for (i = 0, j = tempCategorizedVMs[category].length; i < j; i++) {
                        // let evidence = []
                        // if (
                        //     categorizedVMs[category][i].linkedEvidence &&
                        //     categorizedVMs[category][i].linkedEvidence.length > 0
                        // ) {
                        //     evidence = await callSecureAPI(
                        //         endpoints.getLinkedEvidence.url,
                        //         endpoints.getLinkedEvidence.method,
                        //         token,
                        //         { listOfEvidence: categorizedVMs[category][i].linkedEvidence }
                        //     )
                        //     evidence = evidence.data
                        // }
                        //
                        let vmEvidenceCharCount = 0
                        // for (let ev of evidence) {
                        //     vmEvidenceCharCount += ev.name.length
                        // }

                        // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                        // eslint-disable-next-line no-loop-func
                        function createSlide8() {
                            var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                            newSlide.addTable(headersCopy, {
                                x: 0.5,
                                y: 0.7,
                                colW: [3, 3, 3],
                                // border: [{ color: textColor }, { color: textColor }, { color: textColor }, { color: textColor }],
                                border: { type: 'none' },
                                fill: backgroundColor,
                                color: textColor,
                                margin: 10,
                                rowH: 0.4,
                            })

                            vmsOnCurrentSlide = 0
                            evidenceOnCurrentSlide = 0
                        }

                        if (
                            currentCharCount + vmEvidenceCharCount > MAX_CHARS_PER_SLIDE ||
                            vmsOnCurrentSlide > 6 ||
                            evidenceOnCurrentSlide > 15
                        ) {
                            createSlide8()
                            // reset counter and headers
                            currentCharCount = 0
                            headersCopy = Array.from(tableRowsWithHeaders)
                        }

                        // COMMENTED OUT WITH VMS PER SLIDE AT 3, THIS IS TO BE USED LATER
                        // WHEN MAKING CONFIGURABLE

                        // if (
                        //     currentCharCount + vmEvidenceCharCount > MAX_CHARS_PER_SLIDE ||
                        //     vmsOnCurrentSlide > 3 ||
                        //     evidenceOnCurrentSlide > 15
                        // ) {
                        //     createSlide()
                        //     // reset counter and headers
                        //     currentCharCount = 0
                        //     headersCopy = Array.from(tableRowsWithHeaders)
                        // }

                        let rowColor = i % 2 === 0 ? grey : white
                        // add this VM to current working slide
                        let rows = [
                            {
                                text: `${i + 1}. ${tempCategorizedVMs[category][i].name}`,
                                options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                            },
                        ]

                        let bullets = []
                        let actBullets = []
                        let actBulletsObj = []
                        if (
                            gapMap[tempCategorizedVMs[category][i]._id] &&
                            gapMap[tempCategorizedVMs[category][i]._id].length > 0
                        ) {
                            for (let gap of gapMap[tempCategorizedVMs[category][i]._id]) {
                                bullets.push({
                                    text: gap.text,
                                    options: { bullet: true, fontSize: 9 },
                                })
                                for (let act of gap.linkedActivities) {
                                    actBullets.push(activitiesMap[act].title)
                                }
                            }
                        } else {
                            bullets = ''
                        }

                        if (actBullets.length > 0) {
                            for (let act of actBullets) {
                                actBulletsObj.push({
                                    text: act,
                                    options: { bullet: true, fontSize: 9 },
                                })
                            }
                        } else {
                            actBulletsObj = ''
                        }

                        rows.push({ text: bullets, options: { fill: rowColor } })
                        rows.push({ text: actBulletsObj, options: { fill: rowColor } })

                        // let bullets = []
                        // if (evidence && evidence.length > 0) {
                        //     for (let ev of evidence) {
                        //         evidenceOnCurrentSlide += 1
                        //         bullets.push({
                        //             text: ev.name,
                        //             options: { bullet: true, fontSize: 9 },
                        //         })
                        //     }
                        // } else {
                        //     bullets = ''gi
                        // }
                        // rows.push({ text: bullets, options: { fill: rowColor } })
                        headersCopy.push(rows)
                        currentCharCount += vmEvidenceCharCount
                        vmsOnCurrentSlide += 1

                        // last VM
                        if (i === j - 1) {
                            createSlide8()
                        }
                    }
                }
                break
            case vmsDisplayName + ' + Status + Gaps':
                for (let category of Object.keys(tempCategorizedVMs)) {
                    let i,
                        j,
                        currentCharCount = 0,
                        tableRowsWithHeaders = [],
                        headers = [
                            {
                                text: tempCategoryMap[category].name + ' ' + vmsDisplayName,
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                            {
                                text: 'STATUS',
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                            {
                                text: 'GAPS',
                                options: {
                                    fill: backgroundColor,
                                    color: pink,
                                    valign: 'middle',
                                    align: 'left',
                                    bold: true,
                                    // TRBL order
                                    border: [
                                        { color: white },
                                        { color: white },
                                        { color: pink, pt: 3 },
                                        { color: white },
                                    ],
                                },
                            },
                        ]

                    // Set Up Table headers
                    tableRowsWithHeaders.push(headers)
                    let headersCopy = Array.from(tableRowsWithHeaders)

                    // ITERATE THROUGH VM'S IN EACH CATEGORY, PAGINATING IN CHUNKS
                    let vmsOnCurrentSlide = 0
                    let evidenceOnCurrentSlide = 0
                    for (i = 0, j = tempCategorizedVMs[category].length; i < j; i++) {
                        // let evidence = []
                        // if (
                        //     categorizedVMs[category][i].linkedEvidence &&
                        //     categorizedVMs[category][i].linkedEvidence.length > 0
                        // ) {
                        //     evidence = await callSecureAPI(
                        //         endpoints.getLinkedEvidence.url,
                        //         endpoints.getLinkedEvidence.method,
                        //         token,
                        //         { listOfEvidence: categorizedVMs[category][i].linkedEvidence }
                        //     )
                        //     evidence = evidence.data
                        // }
                        //
                        let vmEvidenceCharCount = 0
                        // for (let ev of evidence) {
                        //     vmEvidenceCharCount += ev.name.length
                        // }

                        // Ignoring no-loop-func warning currently because behavior is stable, need to persist presentation
                        // eslint-disable-next-line no-loop-func
                        function createSlide9() {
                            var newSlide = pres.addSlide({ masterName: 'CONTENT_SLIDE' })
                            newSlide.addTable(headersCopy, {
                                x: 0.5,
                                y: 0.7,
                                colW: [5, 2, 2],
                                // border: [{ color: textColor }, { color: textColor }, { color: textColor }, { color: textColor }],
                                border: { type: 'none' },
                                fill: backgroundColor,
                                color: textColor,
                                margin: 10,
                                rowH: 0.4,
                            })

                            vmsOnCurrentSlide = 0
                            evidenceOnCurrentSlide = 0
                        }

                        if (
                            currentCharCount + vmEvidenceCharCount > MAX_CHARS_PER_SLIDE ||
                            vmsOnCurrentSlide > 6 ||
                            evidenceOnCurrentSlide > 15
                        ) {
                            createSlide9()
                            // reset counter and headers
                            currentCharCount = 0
                            headersCopy = Array.from(tableRowsWithHeaders)
                        }

                        // COMMENTED OUT WITH VMS PER SLIDE AT 3, THIS IS TO BE USED LATER
                        // WHEN MAKING CONFIGURABLE

                        // if (
                        //     currentCharCount + vmEvidenceCharCount > MAX_CHARS_PER_SLIDE ||
                        //     vmsOnCurrentSlide > 3 ||
                        //     evidenceOnCurrentSlide > 15
                        // ) {
                        //     createSlide()
                        //     // reset counter and headers
                        //     currentCharCount = 0
                        //     headersCopy = Array.from(tableRowsWithHeaders)
                        // }

                        let rowColor = i % 2 === 0 ? grey : white
                        // add this VM to current working slide
                        let rows = [
                            {
                                text: `${i + 1}. ${tempCategorizedVMs[category][i].name}`,
                                options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                            },
                            {
                                text: `  ◉`,
                                options: {
                                    fontSize: 18,
                                    fill: rowColor,
                                    fontFace: 'Arial',
                                    color: tempCategorizedVMs[category][i].known ? '07cc9e' : 'fe9c2b',
                                },
                            },
                            {
                                text: ``,
                                options: { fontSize: 10, fill: rowColor, fontFace: 'Arial' },
                            },
                        ]
                        // let bullets = []
                        // if (evidence && evidence.length > 0) {
                        //     for (let ev of evidence) {
                        //         evidenceOnCurrentSlide += 1
                        //         bullets.push({
                        //             text: ev.name,
                        //             options: { bullet: true, fontSize: 9 },
                        //         })
                        //     }
                        // } else {
                        //     bullets = ''gi
                        // }
                        // rows.push({ text: bullets, options: { fill: rowColor } })
                        headersCopy.push(rows)
                        currentCharCount += vmEvidenceCharCount
                        vmsOnCurrentSlide += 1

                        // last VM
                        if (i === j - 1) {
                            createSlide9()
                        }
                    }
                }
                break
            default:
                break
        }

        await pres.writeFile(
            'Atlas Roadmap Export - ' +
                reportType +
                ' - ' +
                date.toLocaleString('en', { month: 'short' }) +
                ' ' +
                date.getDate() +
                ', ' +
                date.getFullYear() +
                '.pptx'
        )
        switch (reportType) {
            case vmsDisplayName:
                setVMExporting(false)
                break
            case vmsDisplayName + ' + Evidence':
                setVMAndEvidenceExporting(false)
                break
            case vmsDisplayName + ' + ' + activitiesDisplayName:
                setVMAndActExporting(false)
                break
            case 'Strategic Imperatives + ' + activitiesDisplayName:
                setSIAndActExporting(false)
                break
            case 'Evidence':
                setEvidenceExporting(false)
                break
            case activitiesDisplayName:
                setActExporting(false)
                break
            case 'EGP Objectives + ' + activitiesDisplayName:
                setEGPExporting(false)
                break
            case vmsDisplayName + ' + Gaps':
                setVMsGapsExporting(false)
                break
            case vmsDisplayName + ' + Status + Gaps':
                setVMsStatusGapsExporting(false)
                break
            default:
                break
        }
    }

    function defineContentSlide(presentation, org) {
        let productFor = `${store.getState().strategy.product} for `
        let indication = store.getState().strategy.indication

        presentation.defineSlideMaster({
            title: 'CONTENT_SLIDE',
            bkgd: backgroundColor,
            color: textColor,
            objects: [
                { image: { data: org.image, x: 8.2, y: 0.15, w: 1.5, h: 0.3 } },
                { image: { data: whiteAtlasLogo, x: 0.2, y: 5.26, w: 1.2, h: 0.28892455858 } },
                {
                    text: {
                        text: org.confidentialityFooter,
                        options: {
                            fontSize: 8,
                            color: confidentialityFooterGrey,
                            align: 'center',
                            fontFace: 'Arial',
                            breakLine: true,
                            y: 5.25,
                            x: '1.75',
                            w: '65%',
                        },
                    },
                },
                {
                    text: {
                        text: valueAndAccessDisplayName + ' Roadmap',
                        options: {
                            fontSize: 7,
                            color: textColor,
                            align: 'left',
                            fontFace: 'Arial',
                            breakLine: true,
                            y: 5.26,
                            x: 1.4,
                            w: 1.3,
                        },
                    },
                },
                {
                    text: {
                        text: [
                            {
                                text: productFor,
                            },
                            {
                                text: indication,
                                options: {
                                    italic: true,
                                },
                            },
                        ],
                        options: {
                            fontSize: 21,
                            color: textColor,
                            bold: true,
                            fontFace: 'Arial',
                            y: 0.14,
                            x: 0.45,
                            w: '90%',
                        },
                    },
                },
            ],

            slideNumber: { color: textColor, x: 9.3, y: 5.23 },
        })
    }

    function defineTitleSlide(presentation, org) {
        let productFor = `${store.getState().strategy.product} for `
        let indication = store.getState().strategy.indication
        presentation.defineSlideMaster({
            title: 'MASTER_SLIDE',
            bkgd: backgroundColor,
            color: white,
            objects: [
                { image: { data: litePowerpointTitleBackground, x: 0, y: 0, w: '100%', h: '100%' } },
                { image: { data: atlasRoadMapValueLogoBlack, x: 0.22, y: 0.15, w: 4, h: 0.55 } },
                { image: { data: org.image, x: 8.2, y: 0.15, w: 1.5, h: 0.3 } },
                { image: { data: gradientLine, x: 0.62, y: 2.4, w: '85%', h: '2%' } },
                {
                    text: {
                        text: org.confidentialityFooter,
                        options: {
                            fontSize: 8,
                            color: confidentialityFooterGrey,
                            align: 'center',
                            fontFace: 'Arial',
                            breakLine: true,
                            y: 5.25,
                            x: '1.75',
                            w: '65%',
                        },
                    },
                },
                {
                    text: {
                        text: [
                            {
                                text: productFor,
                            },
                            {
                                text: indication,
                                options: {
                                    italic: true,
                                },
                            },
                        ],
                        options: {
                            fontSize: 30,
                            color: textColor,
                            bold: true,
                            fontFace: 'Arial',
                            y: 1.76,
                            x: 0.5,
                            w: '90%',
                        },
                    },
                },
            ],
            // slideNumber: { color: confidentialityFooterGrey, x: 9.3, y: 5.23 },
        })
    }

    async function addValuePropSlide(presentation) {
        let positioningStatement = store.getState().strategy.positioningStatement
        let slide = presentation.addSlide({ masterName: 'CONTENT_SLIDE' })
        slide.addText(
            [
                {
                    text: store.getState().settings?.displayNames?.valueProposition || 'Loading',
                    options: {
                        color: blueHeaders,
                        fontSize: 24,
                        bold: true,
                        fontFace: 'Arial',
                        align: 'left',
                        isTextBox: true,
                        autoFit: true,
                        shrinkText: true,
                    },
                },
            ],
            { y: 1.9, x: 0.59, w: 2.94, h: 0.96, align: 'left' }
        )
        slide.addText(
            [
                {
                    text: positioningStatement,
                    options: {
                        color: textColor,
                        fontFace: 'Arial',
                        align: 'left',
                        isTextBox: true,
                        autoFit: true,
                        shrinkText: true,
                    },
                },
            ],
            { y: 1.45, x: 3.8, w: 5.7, h: 2.52, align: 'left' }
        )
        slide.addShape(presentation.ShapeType.rect, { x: 0.59, y: 2.84, w: 2.56, h: 0.05, fill: blueHeaders })
    }

    // if user's org has enabled Gap Analyses, add it to the menu

    function cannotExportPPTX(errorString) {
        setErrorModalText(errorString)
        setOpenModal(true)
        setOpenPptxCustomizationModal(false)
    }

    const updateFreeTrialStatus = (organizationData) => {
        dispatch(
            setFreeTrialStatus({
                isFreeTrial: Boolean(organizationData?.isFreeTrial),
                freeTrialStartDate: organizationData?.freeTrialStartDate || null,
            })
        )
    }

    const updatePicoEnabled = (organizationData) => {
        dispatch(setIsPicoEnabled(Boolean(organizationData?.isPicoEnabled)));
    }

    async function getOrg() {
        if (user) {
            let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
            let parsedUserId = user.sub.split('|')
            if (parsedUserId.length >= 2) {
                let userId = parsedUserId.slice(1).join('|')
                let user = await callSecureAPI(
                    endpoints.getUserAuth0.url + userId + '/auth0',
                    endpoints.getUserAuth0.method,
                    token
                )
                if (user.success) {
                    let org = await callSecureAPI(
                        endpoints.getOrganizationById.url + user.data.organizationId,
                        endpoints.getOrganizationById.method,
                        token
                    )
                    if (org.success) {
                        setOrganization(org.data);
                        updateFreeTrialStatus(org.data);
                        updatePicoEnabled(org.data);
                    }
                }
            }
        }
    }

    function getNavbarPageColor(navbarPath, listText) {
        if (
            history.location.pathname === navbarPath ||
            (listText === 'Roadmap' && roadmapSubpages.map((x) => x.path).includes(history.location.pathname)) ||
            (listText === 'Overview' && overviewSubpages.map((x) => x.path).includes(history.location.pathname)) ||
            (listText === 'Actions' && actionsSubpages.map((x) => x.path).includes(history.location.pathname))
        ) {
            return isDarkMode ? '#51b3ff' : '#0049EA'
        } else if (listText === 'Roadmap' && history.location.pathname === '/gap-analysis') {
            return isDarkMode ? '#51b3ff' : '#0049EA'
        } else if (listText === 'Settings' && history.location.pathname === '/settings') {
            return isDarkMode ? '#51b3ff' : '#0049EA'
        }
        return isDarkMode ? '#FFFFFF' : '#4D4D4D'
    }

    let roadmapSubpages = [
        {
            listIcon: (
                <SvgIcon component={StrategicIcon} alt="strategic imperatives icon" fontSize="small" inheritViewBox />
            ),
            listText: strategicDisplayName,
            path: '/strategic-imperatives',
        },
        {
            listIcon: <SvgIcon component={VMIcon} alt="value message icon" fontSize="small" inheritViewBox />,
            listText: 'Value Narratives',
            path: '/value-narratives',
        },
        {
            listIcon: <SvgIcon component={ActivityIcon} alt="activity icon" fontSize="small" inheritViewBox />,
            listText: activitiesDisplayName,
            path: '/activities',
        },
        {
            listIcon: <SvgIcon component={EvidenceIcon} alt="evidence icon" fontSize="small" inheritViewBox />,
            listText: evidenceDisplayName,
            path: '/evidence',
        },
    ]

    let actionsSubpages = [
        {
            listIcon: <SvgIcon component={DownloadIcon} fontSize="small" inheritViewBox />,
            listText: 'Export Roadmap',
            action: beginCustomizingPptx,
        },
        {
            listIcon: <SvgIcon component={ViewSnapshotIcon} fontSize="small" inheritViewBox />,
            listText: 'View Snapshots',
            path: '/snapshots',
        },
    ]

    let overviewSubpages = [
        {
            listIcon: <SvgIcon component={ProductIcon} fontSize="small" inheritViewBox />,
            listText: 'Product Overview',
            path: '/',
        },
        {
            listIcon: <SvgIcon component={MetricsIcon} fontSize="small" inheritViewBox />,
            listText: 'Metrics',
            path: '/metrics',
        },
    ]

    let parentMenuItems = [
        {
            listIcon: <SvgIcon component={HomeIcon} alt="home icon " fontSize="small" inheritViewBox />,
            listText: 'Home',
            path: '/roadmaps',
        },
        {
            listIcon: <SvgIcon component={OverviewIcon} alt="roadmap overview icon" fontSize="small" inheritViewBox />,
            listText: 'Overview',
            menuSubpages: overviewSubpages,
        },
        {
            listIcon: <SvgIcon component={MapIcon} alt="Roadmap icon" fontSize="small" inheritViewBox />,
            listText: 'Roadmap',
            menuSubpages: roadmapSubpages,
        },
        {
            listIcon: <SvgIcon component={ResourcesIcon} alt="action icon" fontSize="small" inheritViewBox />,
            listText: 'Actions',
            menuSubpages: actionsSubpages,
        },
    ]

    async function initMenu() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let org
        if (store.getState().settings && store.getState().settings.organizationId) {
            org = (
                await callSecureAPI(
                    endpoints.getOrganizationById.url + store.getState().settings.organizationId,
                    endpoints.getOrganizationById.method,
                    token
                )
            ).data
        } else {
            return
        }

        if (!org.hideMenuItem || !org.hideMenuItem['Gap Analyses']) {
            roadmapSubpages.push({
                listIcon: <SvgIcon component={GapIcon} alt="gap analyses icon" fontSize="small" inheritViewBox />,
                listText: 'Gap Analyses',
                path: '/gap-analysis',
            })
        }

        if (strat.sourceId) {
            actionsSubpages.push({
                listIcon: <SvgIcon component={ReplyIcon} fontSize="small" inheritViewBox />,
                listText: 'View Current',
                action: handleViewCurrentStrategy,
            })
        } else if (!userReadonly) {
            actionsSubpages.push({
                listIcon: <SvgIcon component={SnapshotIcon} fontSize="small" inheritViewBox />,
                listText: snapshotSaved ? 'Snapshot Saved ✓' : 'Save Snapshot',
                action: () => setOpenSnapshot(true),
            })
        }

        setIsLoaded(true)
        setMenuItems(parentMenuItems)
    }

    useEffect(() => {
        if (forceRefresh) {
            setForceRefresh(false)
        }
        detectSplashAndDashboard()
        if (isAuthenticated) {
            getOrg()
            initMenu()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location, isAuthenticated, store.getState().settings, isDarkMode, forceRefresh])

    const detectSplashAndDashboard = () => {
        const match = props.location.pathname === '/' || props.location.pathname === '/roadmaps'
        setShowStrategy(!match)
    }

    const handleSnapshotCreate = async () => {
        setSnapshotInProgress(true)
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let result = await callAPI(endpoints.createStrategy.url, endpoints.createStrategy.method, token, {
            snapshotSourceId: strategy._id,
            snapshotNote: newSnapshotNote,
        })
        if (result.success) {
            setOpenSnapshot(false)
            setSnapshotSaved(true)
        } else {
            alert('Create snapshot failed')
        }
        setSnapshotInProgress(false)
    }

    // Handling Nav + Drawer interactions
    const [mobileOpen, setMobileOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handlePopoverOpen = (event, index) => {
        setAnchorEl(event.currentTarget)
        setSelectedIndex(index)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleSearchClick = (event) => {
        console.log(event)
        setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget)
        setPopperOpen(!popperOpen)
    }

    const handleSearchClickAway = () => {
        setPopperAnchorEl(null)
        setPopperOpen(false)
    }

    const getListItemIconStyles = (navbarPath, listText) => {
        const color = getNavbarPageColor(navbarPath, listText)
        return {
            minWidth: 30,
            color,
        }
    }

    const drawer = (
        <div>
            <Toolbar>
                <AtlasIcon />
            </Toolbar>
            <Divider />
            <List component="nav">
                {menuItems.map((page, index) => (
                    <div key={index}>
                        <ListItemButton
                            onClick={() => {
                                if (page.menuSubpages) {
                                    if (openSubMenuIndex === index) {
                                        setOpenSubMenuIndex(null) // close if clicking on the already open submenu
                                    } else {
                                        setOpenSubMenuIndex(index) // open the clicked submenu
                                    }
                                } else {
                                    page.action ? page.action() : history.push(page.path)
                                    handleDrawerToggle()
                                }
                            }}>
                            <ListItemIcon sx={getListItemIconStyles(page.path, page.listText)}>
                                {page.listIcon}
                            </ListItemIcon>
                            <ListItemText>
                                <Typography color={getNavbarPageColor(page.path, page.listText)}>
                                    {page.listText}
                                </Typography>
                            </ListItemText>
                            {page.menuSubpages ? (
                                openSubMenuIndex === index ? (
                                    <ExpandLess color={getNavbarPageColor(page.path, page.listText)} />
                                ) : (
                                    <ExpandMore color={getNavbarPageColor(page.path, page.listText)} />
                                )
                            ) : null}
                        </ListItemButton>
                        <Collapse in={openSubMenuIndex === index} timeout="auto" unmountOnExit>
                            {page.menuSubpages &&
                                page.menuSubpages.map((subPage, subIndex) => (
                                    <ListItemButton
                                        dense
                                        sx={{ ml: 2 }}
                                        key={subIndex}
                                        onClick={() => {
                                            if (subPage.action) {
                                                subPage.action()
                                            } else {
                                                history.push(subPage.path, subPage.listText)
                                            }
                                            handleDrawerToggle()
                                        }}>
                                        <ListItemIcon sx={getListItemIconStyles(subPage.path, subPage.listText)}>
                                            {subPage.listIcon}
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography color={getNavbarPageColor(subPage.path, subPage.listText)}>
                                                {subPage.listText}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                ))}
                        </Collapse>
                    </div>
                ))}
            </List>
            <Divider />
        </div>
    )

    function flattenMarkets() {
        let keyMarkets = []
        for (let phase of strat.keyMarkets) {
            keyMarkets = keyMarkets.concat(phase.phaseKeyMarkets)
        }
        return keyMarkets
    }

    const menuListStyles = useMenuListStyles()

    return (
        <Fade in={isLoaded} timeout={800}>
            <Grid
                container
                wrap="nowrap"
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    backgroundImage: `url(${currentBg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0 45vh',
                    justifyContent: 'space-between',
                    position: 'relative',
                }}
                direction="column">
                <Grid container item>
                    <ElevationScroll {...props}>
                        <AppBar position="static" color="inherit" enableColorOnDark>
                            <Toolbar>
                                <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                                    <Grid item sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} wrap="nowrap">
                                        <IconButton
                                            aria-label="menu"
                                            onClick={handleDrawerToggle}
                                            color="inherit"
                                            edge="start">
                                            <MenuIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }} wrap="nowrap">
                                        <Link component={RouterLink} color="secondary" to="/" underline="none">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                {organization._id === '603d718dabe119e3a2b22688' ? (
                                                    <SvgIcon
                                                        component={SpotIcon}
                                                        inheritViewBox
                                                        sx={{
                                                            height: '100%',
                                                            width: '95px',
                                                        }}
                                                    />
                                                ) : (
                                                    <AtlasIcon />
                                                )}
                                            </Box>
                                        </Link>
                                    </Grid>
                                    <Grid container item alignItems="center" wrap="nowrap">
                                        {menuItems &&
                                            menuItems.map((page, index) => (
                                                <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} key={index}>
                                                    <ListItemButton
                                                        aria-controls={open ? `simple-menu-${index}` : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={(event) => {
                                                            if (page.menuSubpages) {
                                                                handlePopoverOpen(event, index)
                                                            } else if (page.action) {
                                                                page.action()
                                                            } else if (page.path) {
                                                                history.push(page.path)
                                                            }
                                                        }}
                                                        style={{
                                                            color: getNavbarPageColor(page.path, page.listText),
                                                        }}>
                                                        <ListItemIcon
                                                            sx={getListItemIconStyles(page.path, page.listText)}>
                                                            {page.listIcon}
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography
                                                                variant="menu1"
                                                                color={getNavbarPageColor(page.path, page.listText)}>
                                                                {page.listText}
                                                            </Typography>
                                                        </ListItemText>
                                                        {page.menuSubpages && (
                                                            <KeyboardArrowDownIcon
                                                                style={{
                                                                    color: getNavbarPageColor(page.path, page.listText),
                                                                }}
                                                            />
                                                        )}
                                                    </ListItemButton>
                                                    <Menu
                                                        id={`simple-menu-${index}`}
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl) && selectedIndex === index}
                                                        onClose={handlePopoverClose}
                                                        classes={{
                                                            paper: menuListStyles.paper,
                                                            list: menuListStyles.list,
                                                        }}>
                                                        <MenuList
                                                            classes={{
                                                                root: menuListStyles.root,
                                                            }}>
                                                            {page.menuSubpages &&
                                                                page.menuSubpages.map((subPage, subIndex) => (
                                                                    <ListItemButton
                                                                        key={subIndex}
                                                                        onClick={() => {
                                                                            if (subPage.action) {
                                                                                subPage.action()
                                                                            } else {
                                                                                history.push(
                                                                                    subPage.path,
                                                                                    subPage.listText
                                                                                )
                                                                            }
                                                                            handlePopoverClose()
                                                                        }}
                                                                        sx={{
                                                                            padding: '4px 16px !important',
                                                                        }}>
                                                                        <ListItemIcon
                                                                            sx={getListItemIconStyles(
                                                                                subPage.path,
                                                                                subPage.listText
                                                                            )}>
                                                                            {subPage.listIcon}
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                            <Typography
                                                                                variant="menu2"
                                                                                color={getNavbarPageColor(
                                                                                    subPage.path,
                                                                                    subPage.listText
                                                                                )}
                                                                                sx={{ margin: 0 }}>
                                                                                {subPage.listText}
                                                                            </Typography>
                                                                        </ListItemText>
                                                                    </ListItemButton>
                                                                ))}
                                                        </MenuList>
                                                    </Menu>
                                                </Grid>
                                            ))}
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        alignItems="center"
                                        xs="auto"
                                        gap={2}
                                        sx={{
                                            '.MuiGrid-item a': {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: 1,
                                            },
                                        }}>
                                        <Grid item>
                                            <a href={'javascript:void(0)'} style={{ color: 'inherit' }}>
                                                <Tooltip title="Help" enterDelay={600} enterNextDelay={600} arrow>
                                                    <SvgIcon
                                                        component={InfoIcon}
                                                        onClick={() => {
                                                            history.push('/help')
                                                        }}
                                                        fontSize="medium"
                                                        inheritViewBox
                                                    />
                                                </Tooltip>
                                            </a>
                                        </Grid>
                                        {store.getState().strategy &&
                                        !store.getState().strategy.sourceId &&
                                        store.getState().userRecord &&
                                        !store.getState().userRecord.readonly ? (
                                            <Grid item>
                                                <a href={'javascript:void(0)'} style={{ color: 'inherit' }}>
                                                    <Tooltip
                                                        title="Settings"
                                                        enterDelay={600}
                                                        enterNextDelay={600}
                                                        arrow>
                                                        <SvgIcon
                                                            component={SettingsIcon}
                                                            onClick={() => {
                                                                history.push('/settings')
                                                            }}
                                                            fontSize="medium"
                                                            inheritViewBox
                                                        />
                                                    </Tooltip>
                                                </a>
                                            </Grid>
                                        ) : null}
                                        <Grid item>
                                            <a href={'javascript:void(0)'} style={{ color: 'inherit' }}>
                                                <Tooltip title="Search" enterDelay={600} enterNextDelay={600} arrow>
                                                    <SvgIcon
                                                        component={SearchIcon}
                                                        aria-describedby={'searchId'}
                                                        onClick={handleSearchClick}
                                                        fontSize="medium"
                                                        inheritViewBox
                                                    />
                                                </Tooltip>
                                            </a>
                                        </Grid>
                                        <Popper
                                            id={'searchId'}
                                            open={popperOpen}
                                            anchorEl={popperAnchorEl}
                                            placement="bottom-end">
                                            <ClickAwayListener onClickAway={() => handleSearchClickAway()}>
                                                <div>
                                                    <SearchBar
                                                        openTextField={popperOpen}
                                                        manualClickAway={handleSearchClickAway}
                                                    />
                                                </div>
                                            </ClickAwayListener>
                                        </Popper>
                                        <Grid item>
                                            <a href={'javascript:void(0)'} style={{ color: 'inherit' }}>
                                                <Tooltip enterDelay={600} enterNextDelay={600} title="Logout" arrow>
                                                    <SvgIcon
                                                        component={LogoutIcon}
                                                        onClick={() => {
                                                            // props.userLogout()
                                                            logout({ returnTo: window.location.origin })
                                                        }}
                                                        fontSize="medium"
                                                        inheritViewBox
                                                    />
                                                </Tooltip>
                                            </a>
                                        </Grid>
                                        {storeSettings && storeSettings?.enableLocalizedMarkets && (
                                            <Grid item sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}>
                                                <FormControl>
                                                    <Select
                                                        disableUnderline
                                                        variant="standard"
                                                        value={selectedMarket}
                                                        onChange={(e) => props.setLocalMarket(e.target.value)}
                                                        sx={{
                                                            '.MuiSelect-select': {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                pl: 1,
                                                            },
                                                            '.MuiSelect-icon': {
                                                                color: 'text.primary',
                                                            },
                                                        }}
                                                        renderValue={() => {
                                                            return (
                                                                <Tooltip
                                                                    title="Market"
                                                                    enterDelay={600}
                                                                    enterNextDelay={600}
                                                                    arrow>
                                                                    {selectedMarket === 'Global' ? (
                                                                        <SvgIcon
                                                                            component={GlobalIcon}
                                                                            fontSize="medium"
                                                                            inheritViewBox
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <PulsingCircle duration={7500} />
                                                                            <ReactCountryFlag
                                                                                countryCode={selectedMarket?.toLowerCase()}
                                                                                svg
                                                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                                cdnSuffix="svg"
                                                                                style={{
                                                                                    verticalAlign: 'unset',
                                                                                    height: '22.5px',
                                                                                    width: '22.5px',
                                                                                }}
                                                                                title={countryLookup[selectedMarket]}
                                                                                className="countryFlag"
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Tooltip>
                                                            )
                                                        }}>
                                                        <MenuItem value="Global">
                                                            <ListItemIcon
                                                                sx={{
                                                                    minWidth: 'auto !important',
                                                                    mr: 1,
                                                                    color: 'inherit',
                                                                }}>
                                                                <SvgIcon
                                                                    component={GlobalIcon}
                                                                    fontSize="small"
                                                                    inheritViewBox
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText>Global</ListItemText>
                                                        </MenuItem>
                                                        {strat &&
                                                            flattenMarkets().map((e, i) => {
                                                                return (
                                                                    <MenuItem value={e.code} key={i}>
                                                                        <ReactCountryFlag
                                                                            countryCode={e.code?.toLowerCase()}
                                                                            svg
                                                                            style={{ marginRight: '8px' }}
                                                                        />
                                                                        {e.label}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {!_.isEmpty(organization) && (
                                            <Grid item sx={{ display: { xs: 'flex', md: 'none', lg: 'flex' } }}>
                                                <img
                                                    alt={organization.name + ' Logo'}
                                                    src={isDarkMode ? organization.image : organization.lightModeImage}
                                                    className="orgLogo"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <NavbarInfoBanner />
                        </AppBar>
                    </ElevationScroll>
                    <Box component="nav" sx={{ flexShrink: { sm: 0 } }}>
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: DRAWER_WIDTH,
                                },
                            }}>
                            {drawer}
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none' },
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: DRAWER_WIDTH,
                                },
                            }}
                            open>
                            {drawer}
                        </Drawer>
                    </Box>
                </Grid>
                <Grid
                    container
                    item
                    direction="column"
                    wrap="nowrap"
                    justifyContent="space-between"
                    component="main"
                    flex={1}>
                    {props.children}
                    {!_.isEmpty(organization) && (
                        <Box
                            p={1}
                            sx={{
                                backgroundColor: isDarkMode ? 'rgba(0,0,0,.2)' : 'rgba(255,255,255,.2)',
                            }}>
                            <footer>
                                <Typography variant="subtitle5">{organization.confidentialityFooter}</Typography>
                            </footer>
                        </Box>
                    )}
                </Grid>
                <Dialog
                    onClose={() => setOpenSnapshot(false)}
                    aria-labelledby="customized-dialog-snapshot"
                    fullWidth
                    open={openSnapshot}>
                    <DialogTitle id="customized-dialog-shapshot">
                        <Typography variant="h3">Capture Snapshot</Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            id="snapshot-note"
                            placeholder="Snapshot note (optional)"
                            fullWidth
                            onChange={(e) => setNewSnapshotNote(e.target.value)}
                            disabled={snapshotInProgress}
                            onFocus={(event) => {
                                event.target.setAttribute('autocomplete', 'off')
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={snapshotInProgress}
                            autoFocus
                            onClick={() => setOpenSnapshot(false)}
                            variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            disabled={snapshotInProgress}
                            autoFocus
                            onClick={handleSnapshotCreate}
                            variant="contained">
                            {snapshotInProgress ? '⏳' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">{'Unable to export slide deck'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">{errorModalText}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModal(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openPptxCustomizationModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenPptxCustomizationModal(false)}
                    aria-labelledby="alert-dialog-slide-title1"
                    aria-describedby="alert-dialog-slide-description1">
                    <DialogTitle id="alert-dialog-slide-title1">
                        <Typography variant="h3">Customize Roadmap Export</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={vmsDisplayName} />

                                <ListItemSecondaryAction>
                                    {vmExporting ? (
                                        <CircularProgress />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX(vmsDisplayName)
                                                setVMExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={vmsDisplayName + ' + Evidence'} />
                                <ListItemSecondaryAction>
                                    {vmAndEvidenceExporting ? (
                                        <CircularProgress />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX(vmsDisplayName + ' + Evidence')
                                                setVMAndEvidenceExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={vmsDisplayName + ' + ' + activitiesDisplayName} />
                                <ListItemSecondaryAction>
                                    {vmAndActExporting ? (
                                        <CircularProgress />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX(vmsDisplayName + ' + ' + activitiesDisplayName)
                                                setVMAndActExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Evidence" />
                                <ListItemSecondaryAction>
                                    {evidenceExporting ? (
                                        <CircularProgress />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX('Evidence')
                                                setEvidenceExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={activitiesDisplayName} />
                                <ListItemSecondaryAction>
                                    {actExporting ? (
                                        <CircularProgress />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX(activitiesDisplayName)
                                                setActExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={'EGP Objectives + ' + activitiesDisplayName} />
                                <ListItemSecondaryAction>
                                    {EGPExporting ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX('EGP Objectives + ' + activitiesDisplayName)
                                                setEGPExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={vmsDisplayName + ' + Gaps'} />
                                <ListItemSecondaryAction>
                                    {vmsGapsExporting ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX(vmsDisplayName + ' + Gaps')
                                                setVMsGapsExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={vmsDisplayName + ' + Status + Gaps'} />
                                <ListItemSecondaryAction>
                                    {vmsStatusGapsExporting ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                exportPPTX(vmsDisplayName + ' + Status + Gaps')
                                                setVMsStatusGapsExporting(true)
                                            }}>
                                            <GetAppIcon color={'primary'} />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <p>Optional Slides:</p>
                            <Grid container>
                                <FormControlLabel
                                    control={
                                        <WhiteOutlineCheckbox
                                            checked={includesValueProp}
                                            onChange={() => setIncludesValueProp(!includesValueProp)}
                                            name={store.getState().settings?.displayNames?.valueProposition}
                                        />
                                    }
                                    label={store.getState().settings?.displayNames?.valueProposition}
                                />
                            </Grid>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenPptxCustomizationModal(false)
                                setIncludesValueProp(false)
                            }}>
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Fade>
    )
}

const Layout = connect(null, mapDispatchToProps)(ConnectedLayout)

export default withRouter(Layout)
