import { makeStyles } from '@mui/styles'

export const usePicoWidgetStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        position: 'relative',
        height: '300px',
        width: '300px',
        padding: theme.spacing(3),
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20) !important',
        borderRadius: '24px !important',
        fontFamily: 'acumin-pro',
    },
    innerContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(6),
    },
    largePrimaryText: {
        fontSize: '48px !important',
        fontWeight: '600 !important',
        lineHeight: '1 !important',
        color: theme.palette.primary.main,
    },
}))
