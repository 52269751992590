import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { useAuth0 } from '../../../contexts/auth0-context'
import callAPI from '../../../API/callSecureAPI'
import endpoints from '../../../API/endpoints'

export type ValueMessage = any

export type ValueNarrative = {
    _id: string
    name: string
    categoryId: string
    valueMessages: ValueMessage[]
}

export type ValueNarrativeCategory = {
    _id: string
    name: string
    narratives: ValueNarrative[]
    order: number
}

const ONE_MINUTE = 1000 * 60;

export const useGetValueNarrativesQuery = () => {
    const { getTokenSilently, isAuthenticated, isLoading } = useAuth0()

    // @ts-expect-error store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)
    // @ts-expect-error store is not typed properly
    const organizationId = useSelector((state) => state.strategy?.organizationId)

    const getValueNarratives = async () => {
        try {
            const token = await getTokenSilently?.({ audience: 'https://atlas.aesara.com' })

            const { url, method } = endpoints.getValueNarratives

            const response = await callAPI(url + strategyId + '/' + organizationId, method, token)

            return response.data || []
        } catch (e) {
            console.log(e)
        }
    }

    return useQuery<ValueNarrativeCategory[]>(['valueNarratives', strategyId], getValueNarratives, {
        enabled: !!getTokenSilently && !!isAuthenticated && !isLoading,
        cacheTime: ONE_MINUTE,
        staleTime: ONE_MINUTE,
        retry: 3,
    })
}
